<template>
    <div class="page-content">
        <div class="grid">
            <div class="page-list-body">
                <div class="row no-gutters page-list-header">
                    <div class="col l-6 m-6 c-12">
                        <h4 class="page-list-title"><md-icon>list_alt</md-icon> <span>Dự toán chi phí vận tải</span></h4>
                    </div>
                    <div class="col l-6 m-6 c-12">
                        <div class="page-list-action"> 
                            <md-button v-shortkey="['ctrl', 'i']" @shortkey="print()" @click="print()" class="md-raised md-view btn-add"><span>I</span>n<md-tooltip>In đề nghị (Ctrl + I)</md-tooltip></md-button>
                            <md-button v-if="entity.quotationId > 0" v-shortkey="['ctrl', 'x']" @shortkey="viewAttachFile()" @click="viewAttachFile()" class="md-raised md-view btn-add"><span>X</span>em file đính kèm<md-tooltip>Xem file đính kèm (Ctrl + X)</md-tooltip></md-button>
                            <router-link v-if="entity.invoiceId == 0 && entity.status == 2" :to="'/repair-invoice/crud?est=' + entity.id">
                                <md-button v-if="entity.invoiceId == 0" v-shortkey="['ctrl', 'q']" class="md-raised md-view btn-add"><span>Q</span>uyết toán<md-tooltip>Quyết toán (Ctrl + Q)</md-tooltip></md-button>
                            </router-link>
                            <md-button v-if="entity.status == 2 && entity.invoiceId > 0" v-shortkey="['ctrl', 'e']" @shortkey="viewInvoice()" @click="viewInvoice()" class="md-raised md-view btn-add">X<span>e</span>m hóa đơn<md-tooltip>Xem hóa đơn (Ctrl + E)</md-tooltip></md-button>
                        </div>
                    </div>
                </div>
                <div class="form-body">
                    <div class="wrapper">
                        <div class="row no-gutters">
                            <div class="col l-4 m-4 c-12">
                                <div class="left">
                                    <img src="../../../assets/images/logo.png" />
                                </div>
                            </div>
                            <div class="col l-8 m-8 c-12">
                                <div class="center">
                                    <div class="title"><h2>DỰ TOÁN CHI PHÍ VẬN TẢI</h2></div>
                                    <div class="code"><span class="label">Số: </span><span>{{entity.estimateCode}}</span></div>
                                    <div class="date"><span class="label">Ngày: </span><span>{{entity.estimateDate}}</span></div>
                                </div>
                            </div>
                        </div>
                        <div class="row no-gutters" style="padding-top:10px;">
                            <div class="col l-12 m-12 c-12">
                                <div class="line"> 
                                    <label>Mã lệnh điều động: </label>
                                    <div>{{entity.job.jobCode}}</div>
                                </div>
                                <div class="line"> 
                                    <label>Người lập phiếu: </label>
                                    <div>{{entity.staff.fullName}}</div>
                                </div>
                                <div class="line-2">
                                    <div class="line"> 
                                        <label>Ghi chú: </label>
                                        <div>{{entity.note}}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                        <div class="row no-gutters">
                            <div class="col l-12 m-12 c-12" style="padding-top:20px;">
                                <table class="table-1">
                                    <thead>
                                        <tr>
                                            <th style="width:80px;">STT</th>
                                            <th style="width:100px;">Kiện hàng</th>
                                            <th style="width:150px;">Tên hàng</th>
                                            <th style="width:120px;">Tên phí</th>
                                            <th style="width:100px;">Loại</th>
                                            <th style="width:100px;">Số tiền</th>
                                            <th style="width:100px;">VAT</th>
                                            <th style="width:100px;">Thành tiền</th>
                                            <th style="width:100px;">Phê duyệt</th>
                                            <th style="width:200px;">Ghi chú</th>
                                        </tr>
                                    </thead>
                                    <tbody v-for="(item, index) in entity.details" :key="item.feeName">
                                        <tr>
                                            <td class="stt"><span style="font-weight:bold;">{{index + 1}}</span></td>
                                            <td></td>
                                            <td>Phí chung</td>
                                            <td>{{item.fee.feeName}}</td>
                                            <td>
                                                <div v-if="item.type == 1">Chi phí</div>
                                                <div v-else>Chi hộ</div>
                                            </td>
                                            <td class="currency">
                                                <div class="group-control">
                                                    <div v-if="item.isApproved == false">
                                                        <number @change="calSum(item)" v-model="item.amount" v-bind="currency" class="form-control currency"></number> 
                                                    </div>
                                                    <div v-else>
                                                        {{item.price}}
                                                    </div>
                                                </div>
                                            </td>
                                            <td class="currency">
                                                <div class="group-control">
                                                    <div v-if="item.isApproved == false">
                                                        <number @change="calSum(item)" v-model="item.vat" v-bind="currency" class="form-control currency"></number> 
                                                    </div>
                                                    <div v-else>
                                                        {{item.vat}}
                                                    </div>
                                                </div>
                                            </td>
                                            <td class="currency">
                                                <div class="group-control">
                                                    <div v-if="item.isApproved == false">
                                                        <number @change="calAmount(item)" v-model="item.pay" v-bind="currency" class="form-control currency"></number> 
                                                    </div>
                                                    <div v-else>
                                                        {{item.pay}}
                                                    </div>
                                                </div>
                                            </td>
                                            <td class="stt">
                                                <md-checkbox :disabled="entity.status != 1" v-model="item.isApproved" class="md-primary"></md-checkbox>
                                            </td>
                                            <td>
                                                <div v-if="(entity.status == 1 || entity.status == 4) && (item.isApproved == false)" class="group-control">
                                                    <input v-model="item.note" class="form-control" type="text">
                                                </div>
                                                <div v-else>
                                                    {{item.note}}
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                    <tbody v-for="(item, index) in entity.goodsDetails" :key="item.feeName">
                                        <tr>
                                            <td class="stt"><span style="font-weight:bold;">{{index + 1}}</span></td>
                                            <td>{{item.orderDetail.serial}}</td>
                                            <td>{{item.orderDetail.goods.goodsName}}</td>
                                            <td>{{item.fee.feeName}}</td>
                                            <td>
                                                <div v-if="item.type == 1">Chi phí</div>
                                                <div v-else>Chi hộ</div>
                                            </td>
                                            <td class="currency">
                                                <div class="group-control">
                                                    <div v-if="item.isApproved == false">
                                                        <number @change="calSum2(item)" v-model="item.amount" v-bind="currency" class="form-control currency"></number> 
                                                    </div>
                                                    <div v-else>
                                                        {{item.amount}}
                                                    </div>
                                                </div>
                                            </td>
                                            <td class="currency">
                                                <div class="group-control">
                                                    <div v-if="item.isApproved == false">
                                                        <number @change="calSum2(item)" v-model="item.vat" v-bind="currency" class="form-control currency"></number> 
                                                    </div>
                                                    <div v-else>
                                                        {{item.vat}}
                                                    </div>
                                                </div>
                                            </td>
                                            <td class="currency">
                                                <div class="group-control">
                                                    <div v-if="item.isApproved == false">
                                                        <number @change="calAmount2(item)" v-model="item.pay" v-bind="currency" class="form-control currency"></number> 
                                                    </div>
                                                    <div v-else>
                                                        {{item.pay}}
                                                    </div>
                                                </div>
                                            </td>
                                            <td class="stt">
                                                <md-checkbox :disabled="entity.status != 1" v-model="item.isApproved" class="md-primary"></md-checkbox>
                                            </td>
                                            <td>
                                                <div v-if="(entity.status == 1 || entity.status == 4) && (item.isApproved == false)" class="group-control">
                                                    <input v-model="item.note" class="form-control" type="text">
                                                </div>
                                                <div v-else>
                                                    {{item.note}}
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                    <tbody>
                                        <tr>
                                            <td colspan="9" class="stt"><b>Tổng</b></td>
                                            <td class="currency">{{formatNumber(entity.amount)}}</td>
                                        </tr>
                                        <tr>
                                            <td colspan="9" class="stt"><b>Thuế VAT</b></td>
                                            <td class="currency">{{formatNumber(entity.vat)}}</td>
                                        </tr>
                                        <tr>
                                            <td colspan="9" class="stt"><b>Tổng cộng</b></td>
                                            <td class="currency">{{formatNumber(entity.pay)}}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div class="row no-gutters">
                            <div class="col l-12 m-12 c-12">
                                <div class="approve"> 
                                    <div v-for="item in entity.approveList" :key="item.id" class="approve-item">
                                        <div class="title">{{item.title}}</div>
                                        <div class="approve-status">
                                            <md-chip v-if="item.status == 1" class="md-wait">Chờ duyệt</md-chip>
                                            <md-chip v-else-if="item.status == 2" class="md-primary"><i class="uil uil-check"></i> Đã duyệt</md-chip>
                                            <md-chip v-else-if="item.status == 3" class="md-status-2">Yêu cầu chỉnh sửa</md-chip>
                                            <md-chip v-else-if="item.status == 4" class="md-accent">Không phê duyệt</md-chip>
                                        </div>
                                        <div class="name">{{item.staff.fullName}}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="action">
                            <md-button v-shortkey="['ctrl', 'g']" @shortkey="showConfirmRequestApprove()" @click="showConfirmRequestApprove()" class="md-raised md-primary"><span>G</span>ửi phê duyệt<md-tooltip>Gửi phê duyệt (Ctrl + G)</md-tooltip></md-button>
                            <md-button v-shortkey="['ctrl', 'i']" @shortkey="back()" @click="back()" class="md-raised">Quay lạ<span>i</span><md-tooltip>Quay lại (Ctrl + Q)</md-tooltip></md-button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <md-dialog-confirm
            :md-active.sync="showRequestApproveDialog"
            md-title="Bạn có chắc chắn gửi phê duyệt?"
            md-content="" 
            md-confirm-text="Gửi phê duyệt"
            md-cancel-text="Hủy"
            @md-cancel="onCancel"
            @md-confirm="onRequestApproved" />
   </div>
</template>

<script>
    import jobEstimateService from '../../../api/jobEstimateService';
    import messageBox from '../../../utils/messageBox';
    import { mapActions } from 'vuex';
    import common from '../../../mixins';
    import { required } from 'vuelidate/lib/validators';

    export default ({
        metaInfo: {
            title: 'DỰ TOÁN CHI PHÍ VẬN TẢI'
        },
        data() {
            return {
                title: 'DỰ TOÁN CHI PHÍ VẬN TẢI',
                id: 0,
                submitted: false,
                loadding: false,
                details: [],
                obj: {},
                note: '',
                entity: { id: 0, staff: { fullName: '' }, job: { jobCode: '' }, goodsDetails: [], details: [], approveList: [], estimateCode: '', estimateDate: common.dateNow, staffId: 0, status: 0, amount: 0, vat: 0, pay: 0, note: '', isHistory: false },
                showRequestApproveDialog: false,
                vatF: {   
                    decimal: '.',
                    separator: ',',
                    suffix: ' %',
                    precision: 0,
                    masked: false,
                    nullValue: ''
                },
                currency: {   
                    decimal: '.',
                    separator: ',',
                    suffix: '',
                    precision: 0,
                    masked: false,
                    nullValue: ''
                },
                amount: 0,
                vat: 0,
                pay: 0,
                amount2: 0,
                vat2: 0,
                pay2: 0
            }
        },
        created(){
            this.id = this.$route.params.id;
            if(this.id > 0){
                this.getById();
            }
        },
        methods: {
            ...mapActions('common', ['setLoading']),

            calAmount(item){
                const selected = this.entity.details.findIndex(x => x.id == item.id);
                this.entity.details[selected].amount = Math.round((100 * parseFloat(this.entity.details[selected].pay)) / (100 + parseFloat(this.entity.details[selected].vat)));
                this.calSummary();
            },

            calSum(item){
                const selected = this.entity.details.findIndex(x => x.id == item.id);
                let vat = parseFloat(this.entity.details[selected].amount) * (parseFloat(this.entity.details[selected].vat) / 100);
                this.entity.details[selected].pay = Math.round(parseFloat(this.entity.details[selected].amount) + vat);
                this.calSummary();
            },

            calSummary(){
                this.amount = 0;
                this.pay = 0;
                for (var i = 0; i < this.entity.details.length; i++) {
                    this.amount = parseFloat(this.amount) + parseFloat(this.entity.details[i].amount);
                }
                for (var j = 0; j < this.entity.details.length; j++) {
                    this.pay = parseFloat(this.pay) + parseFloat(this.entity.details[j].pay);
                }
                this.vat = this.pay - this.amount;
                this.entity.amount = this.amount + this.amount2;
                this.entity.pay = this.pay + this.pay2;
                this.entity.vat = this.vat + this.vat2;
            },

            calAmount2(item){
                const selected = this.entity.goodsDetails.findIndex(x => x.id == item.id);
                this.entity.goodsDetails[selected].amount = Math.round((100 * parseFloat(this.entity.goodsDetails[selected].pay)) / (100 + parseFloat(this.entity.goodsDetails[selected].vat)));
                this.calSummary2();
            },

            calSum2(item){
                const selected = this.entity.goodsDetails.findIndex(x => x.id == item.id);
                let vat = parseFloat(this.entity.goodsDetails[selected].amount) * (parseFloat(this.entity.goodsDetails[selected].vat) / 100);
                this.entity.goodsDetails[selected].pay = Math.round(parseFloat(this.entity.goodsDetails[selected].amount) + vat);
                this.calSummary2();
            },

            calSummary2(){
                this.amount2 = 0;
                this.pay2 = 0;
                for (var i = 0; i < this.entity.goodsDetails.length; i++) {
                    this.amount2 = parseFloat(this.amount2) + parseFloat(this.entity.goodsDetails[i].amount);
                }
                for (var j = 0; j < this.entity.goodsDetails.length; j++) {
                    this.pay2 = parseFloat(this.pay2) + parseFloat(this.entity.goodsDetails[j].pay);
                }
                this.vat2 = this.pay2 - this.amount2;
                this.entity.amount = this.amount + this.amount2;
                this.entity.pay = this.pay + this.pay2;
                this.entity.vat = this.vat + this.vat2;
            },

            showConfirmRequestApprove(){
                this.showRequestApproveDialog = true;
                this.note = '';
            },

            onCancel () {
                console.log('Cancel');
            },

            onRequestApproved() {
                this.note = "Đồng ý phê duyệt";
                this.status = 2;
                this.submit();
                this.showRequestApproveDialog = false;
            },

            viewInvoice(){
                let route = this.$router.resolve('/job-invoice/view/' + this.entity.invoiceId);
                window.open(route.href, '_blank');
            },

            submitInvoice(){
                this.$refs.invoiceForm.open(this.entity);
            },
            
            submit(){
                jobEstimateService.editApproved(this.entity).then((response) => {
                    if(response.statusCode == 200){
                        this.$router.push('/job-estimate');
                        messageBox.showMessage("Gửi phê duyệt thành công!");
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                }).finally();
            },

            getById(){
                this.setLoading(true);
                jobEstimateService.getById(this.id).then((response) => {
                    if(response.statusCode == 200){
                        this.entity = response.data;
                        this.calSummary();
                        this.calSummary2();
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                }).finally(() => { this.setLoading(false); });
            },
            back(){
                this.$router.push('/job-estimate');
            }
        },
        validations: {
            note: { required },
        }
    })

</script>

<style scoped>
    table.table-1 input {
        height: 30px;
        width: 100%;
        border: 1px solid #e0e0e0;
        padding: 0 5px;
    }
    .md-checkbox {
        margin: 0 !important;
    }
    .md-content {
        padding: 0 15px 0px 15px;
        width: 550px;
        height: 455px;
        max-width: 550px;
        max-height: 150px;
    }
    .dialog-content {
        height: 150px;
    }
    .action {
        position: sticky;
        bottom: 20px;
        height: 80px;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #FFF;
    }
    .wrapper {
        margin: 0 auto;
        width: 1000px;
        padding-top: 20px;
        padding-bottom: 30px;
    }
    .header {
        display: flex;
        justify-content: space-around;
        border: 1px solid #999;
        position: relative;
    }
    .left, .center, .right {
        height: 130px;
    }
    .left {
        border-top: 1px solid #999;
        border-left: 1px solid #999;
        border-bottom: 1px solid #999;
        border-right: 1px solid #999;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .left img{
        height: 100px;
        width: 220px;
    }
    .center {
        border-top: 1px solid #999;
        border-bottom: 1px solid #999;
        border-right: 1px solid #999;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
    .center .title {
        display: flex;
        
    }
    .center .title h2 {
        font-size: 26px;
        text-transform: uppercase;
        font-weight: bold;
    }
    .center .code, .center .date {
        font-size: 18px;
    }
    .label {
        font-weight: bold;
    }
    .approve {
        display: flex;
        justify-content: space-around;
        padding-top: 20px;
    }
    .approve-item {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .approve-item .title {
        font-size: 20px;
        font-weight: bold;
        text-transform: uppercase;
    }
    .approve-item .approve-status {
        padding: 20px 0;
    }
    .approve-item .approve-status .md-chip {
        font-size: 14px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .approve-item .approve-status i{
        color: #00d25b;
        font-size: 25px;
        padding-right: 5px;
    }
    .approve-item .name {
        font-size: 18px;
        font-weight: bold;
    }
    .line {
        display: flex;
        padding-top: 5px;
        align-items: center;
    }
    .line label {
        font-weight: bold;
        padding-right: 10px;
        font-size: 18px;
    }
    .line span{
        font-size: 18px;
        font-weight: bold;
    }
    .line div {
        font-size: 18px;
    }
    .line-item {
        padding-right: 5px;
    }
    .line-item::after {
        content: ',';
    }
    .line-item:last-child::after {
        content: '';
    }
    .line-2 {
        display: flex;
    }
    .line-2 .line:first-child {
        padding-right: 40px;
    }
    .line-3 {
        display: flex;
        justify-content: space-between;
    }
    .table-1 {
        width: 100%;
    }
    .stt {
        text-align: center;
    }
    .table-1 td, .table-1 th {
        font-size: 14px;
    }
    .table-1 {
        min-height: 140px !important;
    }
    .currency {
        text-align: right;
    }
    b {
        font-weight: bold;
        font-size: 17px;
    }
</style>
